<template>
  <div>
    <div class="a-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>订单列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="a-filter-container">
      <el-tabs tab-position="top">
        <el-tab-pane>
          <div slot="label">
            <div
              class="a-filter-item"
              @click="handleFilter('NoneReceived')"
              style="color: #409eff"
            >
              <div class="a-filter-title">待接收</div>
              <div class="a-filter-total">{{ count["NoneReceived"] }}</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label">
            <div class="a-filter-item" @click="handleFilter('Received')">
              <div class="a-filter-title">已接收</div>
              <div class="a-filter-total">{{ count["Received"] }}</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label">
            <div
              class="a-filter-item"
              @click="handleFilter('Printing')"
              style="color: #67c23a"
            >
              <div class="a-filter-title">打印中</div>
              <div class="a-filter-total">{{ count["Printing"] }}</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label">
            <div
              class="a-filter-item"
              @click="handleFilter('PrintCompleted')"
              style="color: #67c23a"
            >
              <div class="a-filter-title">打印完成</div>
              <div class="a-filter-total">{{ count["PrintCompleted"] }}</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label">
            <div
              class="a-filter-item"
              @click="handleFilter('Completed')"
              style="color: #409eff"
            >
              <div class="a-filter-title">已结束</div>
              <div class="a-filter-total">{{ count["Completed"] }}</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label">
            <div
              class="a-filter-item"
              @click="handleFilter('Overdue')"
              style="color: #f56c6c"
            >
              <div class="a-filter-title">逾期</div>
              <div class="a-filter-total">{{ count["Overdue"] }}</div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-table class="a-table" :data="tableData" @row-click="handleRowClick">
      <el-table-column label="订单号" :width="180" prop="orderNo">
        <template slot-scope="scope">
          <span style="cursor: pointer">{{ scope.row.orderNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="模型数量" :width="100">
        <template slot-scope="scope">
          {{ scope.row.models.length }}
        </template>
      </el-table-column>
      <el-table-column label="用户" prop="customerName" />
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag size="small" :type="types[scope.row.state]">{{
            scope.row.state ? status[scope.row.state] : ""
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createTime" />
    </el-table>
    <!-- 分页 -->
    <div class="a-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.currentPage"
        :page-sizes="pageInfo.pageSizes"
        :page-size="pageInfo.pageSize"
        :layout="pageInfo.layout"
        :total="pageInfo.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      status: this.$store.state.status,
      types: this.$store.state.types,
      count: {
        NoneReceived: 0,
        Received: 0,
        Printing: 0,
        Completed: 0,
      },
      /**分页 */
      pageInfo: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        layout: "total, sizes, prev, pager, next, jumper",
        currentPage: 1,
        total: 1,
      },
      loading: true,
    };
  },
  methods: {
    getOrderList(state) {
      this.loading = true;
      const manufacturerId = localStorage.getItem("serviceId");
      /**请求状态次数 */
      this.$axios.getOrderStatus({ manufacturerId }).then((res) => {
        if (!res.error) {
          let data = res.states;
          data.forEach((data) => {
            this.count[data.state] = data.count;
          });
        } else {
          this.$notify.error({
            title: "提示",
            message: res.error,
            duration: 2000,
          });
        }
      });
      const page = {
        pageIndex: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
      };
      /**请求订单列表 */
      this.$axios
        .getOrderList({
          ...page,
          manufacturerId,
          state: state !== undefined ? state : undefined,
        })
        .then((res) => {
          if (!res.error) {
            this.pageInfo.total = res.totalCount;
            res.data.map((servicer) => {
              return (servicer.createTime = this.$utils.dateToString(
                servicer.createTime
              ));
            });
            this.tableData = res.data;
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
          this.loading = false;
        });
    },
    /**列表过滤 */
    handleFilter(state) {
      this.getOrderList(state);
    },
    handleRowClick(row) {
      localStorage.setItem(
        "order",
        JSON.stringify({ orderId: row.id, orderNo: row.orderNo })
      );
      this.$router.push(`order/${row.id}`);
    },
    /**分页 */
    // 页面尺寸改变
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getOrderList();
    },
    // 当前页改变
    handleCurrentChange(val) {
      this.pageInfo.currentPage = val;
      this.getOrderList();
    },
  },
  mounted() {
    this.getOrderList("NoneReceived");
  },
};
</script>

<style lang="scss" scoped>
.a-detail-item {
  margin: 0.5rem 0;
}
.a-detail-operate {
  margin-top: 2rem;
  text-align: center;
}

.a-filter-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  .a-filter-item {
    margin: 0 1rem;
    text-align: center;
    font-size: 1.1rem;
    color: #606266;
    cursor: pointer;
  }
  .el-tabs__item {
    top: -9px !important;
  }
}
</style>

<style lang="scss">
.a-filter-container {
  .el-tabs__item {
    top: -9px !important;
  }
}
</style>
